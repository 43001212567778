
export default {
  name: 'AtomText',
  props: {
    lines: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    }
  }
}
