

export default {
  name: 'AtomSpecsList',
  props: {
    name: {
      required: true,
      type: [String, Object],
      default: ""
    }
  }
}
