
export default {
  name: 'AtomTenants',
  props: {
    tenants: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    }
  },
  methods: {
    resourceUrl(url) {
      if (url.includes('://')) {
        return url
      }
      return `${this.$store.getters.cdnBase}/${url}`
    }
  }
}
