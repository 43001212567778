
import { convertUCWordsOrCamelCaseToDashCase, smoothScrollToTargetId } from '~/helpers/util'

export default {
  name: 'AtomScrollUp',
  data() {
    return {
      isScrolled: false
    }
  },
  mounted() {
    document.addEventListener('scroll', this.initScrollListener)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.initScrollListener)
  },
  methods: {
    initScrollListener() {
      this.isScrolled = window.scrollY > 1000
    },
    goBackToTopSection() {
      const sections = this.$parent.sections
      if (sections && sections.length > 1) {
        const targetSection = sections[0]
        const targetString = convertUCWordsOrCamelCaseToDashCase(targetSection.type)
        smoothScrollToTargetId(`#${targetString}`)
      }
    }
  }
}
