
// const objectsEqual = (o1, o2) =>
//  Object.keys(o1).length === Object.keys(o2).length &&
//  Object.keys(o1).every((p) => o1[p] === o2[p])

// const arraysEqual = (a1, a2) =>
//   a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]))

export default {
  name: 'AtomSelect',
  props: {
    hasIcon: {
      required: false,
      type: Boolean,
      default: false
    },
    id: {
      required: false,
      type: String,
      default: ''
    },
    options: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    },
    selectProps: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    multiple: {
      required: false,
      type: Boolean,
      default: false
    },
    searchable: {
      required: false,
      type: Boolean,
      default: false
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    instance: {
      required: false,
      type: String,
      default: ''
    },
    optionLabel: {
      required: false,
      type: String,
      default: 'label'
    },
    hasLabel: {
      required: false,
      type: Boolean,
      default: false
    },
    labelText: {
      required: false,
      type: String,
      default: 'Label'
    },
    value: {
      required: false,
      type: [Number, String, Array, Object],
      default: null
    },
    clearable: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  methods: {
    emitEvent(value) {
      this.$emit('change', value)
    }
  }
}
